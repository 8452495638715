import * as React from 'react';
import { useTheme } from "@mui/material";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';


const StyledTableCell = styled(TableCell)(({ theme }) => ({

  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FEF0DE",
    color: theme.palette.common.black,
    fontSize: 16
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


export default function DummyTable(props) {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
            <TableRow>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            <StyledTableRow>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
            </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}