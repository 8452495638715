import * as React from 'react';
import axios from "axios";
import { baseURL } from 'services/API';
import { Grid, Box } from "@mui/material";
import TicketsTable from 'components/Tables/TicketsTable';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import BasicSelect from 'components/select';
import FormDialog from 'components/modal';
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import { useGetRentalTickets, useRentals } from 'services/hooks/rentals';
import TicketPopover from 'components/PopOvers/TicketPopover';
import { formatDate } from 'utilities/date';
import TicketDetails from 'components/ModalContent/TicketDetails';
import ProgressScale from 'components/ProgressScale';
import DummyCard from 'components/DummyCard';
import DummyTable from 'components/Tables/DummyTable';

export default function Tickets() {
    const [isSidebar, setIsSidebar] = React.useState(true);
    const [rental, setRental] = React.useState('')
    const [open, setOpen] = React.useState(false);
    const {rentalTickets, isLoadingRentalTickets, errorLoadingRentalTickets} = useGetRentalTickets()
    const {rentals, isLoadingRentals, error} = useRentals()

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openPopper = Boolean(anchorEl);
    const popperId = openPopper ? 'simple-popover' : undefined;

    const handleSelectChange = (event) => {
        setRental(event.target.value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickPopOver = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClosePopOver = () => {
        setAnchorEl(null);
    };

    return (
        <Box>
            <Navbar />
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Sidebar isSidebar={isSidebar}/>  
                </Grid>
                <Grid item xs={10}>
                  {isLoadingRentalTickets && (
                    <ProgressScale />
                  )}
                    <h1>Tickets</h1>
                    <Grid container spacing={4}>
                        <Grid item xs={4}>
                            <BasicSelect value={rental} handleChange={handleSelectChange} label={"Filter Rental"} data={rentals}/>
                        </Grid>
                        <Grid item xs={4}>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant="contained" onClick={handleClickOpen} endIcon={<AddIcon/>}>
                                Add Ticket
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sx={{ marginTop: 3 }}>
                            {isLoadingRentalTickets ? (
                                <DummyTable/>
                            ) : (
                                <TicketsTable rows={rentalTickets} isLoadingRentalTickets={isLoadingRentalTickets} handleClickPopOver={handleClickPopOver} id={popperId} formatDate={formatDate}/>
                            )}
                        </Grid>
                    </Grid>
                    <TicketPopover popperId={popperId} openPopper={openPopper} anchorEl={anchorEl} handleClosePopOver={handleClosePopOver} handleClickOpen={handleClickOpen}/>
                    <FormDialog title={"Ticket Details"} content={<TicketDetails/>} open={open} handleClose={handleClose}/>
                </Grid>
            </Grid>
        </Box>
    )
}