import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';


export default function TenantsPopover({popperId, openPopper, anchorEl, handleClosePopOver, handleClickOpenTenantDetails}) {
    return (
      <div>
        <Popover
          id={popperId}
          open={openPopper}
          anchorEl={anchorEl}
          onClose={handleClosePopOver}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          
        >
            <Stack sx={{
                padding: "10px",
                alignContent: "left"
            }}>
                <Button sx={{
                    color: "black",
                    padding: "10px",
                }}
                onClick={handleClickOpenTenantDetails}
                >Edit Tenant</Button>
                <Divider />
                <Button sx={{
                    color: "blue",
                    padding: "10px"
                }}>Send Message</Button>
                <Divider />
                <Button sx={{
                    color: "red",
                    padding: "10px"
                }}>Delete Tenant</Button>
                <Divider />
            </Stack>
        </Popover>
      </div>
    );
  }