import * as React from 'react';
import axios from "axios";
import { useSelector} from "react-redux";
import { baseURL } from 'services/API';
import { Grid, Box } from "@mui/material";
import TenantsTable from 'components/Tables/TenantsTable';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import BasicSelect from 'components/select';
import FormDialog from 'components/modal';
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import TenantsForm from 'components/Forms/TenantsForm';
import DummyTable from 'components/Tables/DummyTable';
import ProgressScale from 'components/ProgressScale';
import { useGetRentalTenants, useRentals } from 'services/hooks/rentals';
import TopSnack from 'components/TopSnack';
import TenantsPopover from 'components/PopOvers/TenantsPopover';
import { formatDate } from 'utilities/date';
import { Phone } from '@mui/icons-material';

export default function Tenants() {
    const token = useSelector((state) => state.token)
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const [isSidebar, setIsSidebar] = React.useState(true);
    const [rental, setRental] = React.useState('')
    const [unit, setUnit] = React.useState('')
    const [message, setMessage] = React.useState('')
    const [open, setOpen] = React.useState(false);
    const [openTenantDetails, setOpenTenantDetails] = React.useState(false);
    const [openSnack, setOpenSnack] = React.useState(false);
    const [severity, setSeverity] = React.useState("")
    const {rentalTenants, isLoadingRentalTenants, errorLoadingRentalTenants} = useGetRentalTenants()
    const {rentals, isLoadingRentals, error} = useRentals()
    const [isLoadedUnits, setIsLoadedUnits] = React.useState(false);
    const [units, setUnits] = React.useState([])
    const [tenantDetails, setTenantDetails] = React.useState({});
    const [loadedTenant, setLoadedTenant] = React.useState(false);
    const [loadingTenantsError, setLoadingTenantsError] = React.useState("");
    const [isExistingTenant, setIsExisitingTenant] = React.useState(true);
    const [isLoadingTenantDetails, setLoadingTenantDetails] = React.useState(true);
    const [errorLoadingTenantDetails, setErrorLoadingTenantDetails] = React.useState(false);
    const [tenantNumber, setTenantNumber] = React.useState("");
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openPopper = Boolean(anchorEl);
    const popperId = openPopper ? 'simple-popover' : undefined;

    const handleSelectChange = async (event) => {
        setIsLoadedUnits(false)
        setRental(event.target.value);
        try {
            const response = await axios.get(`${baseURL}/landlords/rentals/units/unoccupied?rental_id=${event.target.value}`);
            setUnits(response.data.data);
            setIsLoadedUnits(true)
            return true
        } catch (err) {
            return false
        }
    };

    const handleChangeRentalUnit = (event) => {
        setUnit(event.target.value);
    };

    const handleChangeFirstName = (event) => {
        setFirstName(event.target.value);
    };

    const handleChangeLastName = (event) => {
        setLastName(event.target.value);
    };

    const handleClickOpen = () => {
        setLoadingTenantDetails(false);
        setLoadingTenantsError("")
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpenTenantDetails = () => {
        setOpenTenantDetails(true);
    };

    const handleCloseTenantDetails = () => {
        setOpenTenantDetails(false);
    };

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpenSnack(false);
    };

    const handleClickPopOver = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClosePopOver = () => {
        setAnchorEl(null);
    };

    const handleChangeTenantNumber = async (e) => {
        setLoadedTenant(false)
        setIsExisitingTenant(true)
        setIsLoadedUnits(false)
        setLoadingTenantsError("")
        const tenant_number = e.target.value
        if(tenant_number.length === 12){
            setTenantNumber(tenant_number)
            try {
                const response = await axios.get(`${baseURL}/tenants?username=${tenant_number}`);
                setTenantDetails(response.data.data);
                setLoadedTenant(true)
                setLoadingTenantDetails(false);
                setLoadingTenantsError("")
                return true
            } catch (err) {
                setIsExisitingTenant(false)
                setLoadingTenantDetails(false);
                setErrorLoadingTenantDetails(true);
                setLoadingTenantsError("")
                return false
            }
        } else {
            setLoadingTenantsError("Please type a complete phone number with 12 digits including 256")
        }
    }

    const handleSubmitTenant = async () => {
        try {
            if(isExistingTenant) {
                await axios.post(`${baseURL}/landlords/rentals/units/assign`, {
                    unit_id: unit,
                    tenant_number: tenantDetails.tenant_number,
                });
            } else {
                await axios.post(`${baseURL}/accounts/users`, {
                    first_name: firstName,
                    last_name: lastName,
                    email:"dummy@email.com",
                    phone_number: tenantNumber.slice(3),
                    password: "@Rent123",
                    username: `+${tenantNumber}`,
                    to_activate: "True",
                    unit_id: unit
                });
            }
            setOpen(false);
            setOpenSnack(true);
            setSeverity("success")
            setMessage("Tenant Added Successfully")
        } catch (err) {
            console.log(err)
            setOpenSnack(true);
            setSeverity("error")
            setMessage("Tenant Addition failed, Please check data provided")
        }
    }

    return (
        <Box>
            <Navbar />
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Sidebar isSidebar={isSidebar}/>  
                </Grid>
                <Grid item xs={10}>
                  {isLoadingRentalTenants && (
                    <ProgressScale />
                  )}
                    <h1>Tenants</h1>
                    <Grid container spacing={4}>
                        <Grid item xs={4}>
                            <BasicSelect value={rental} handleChange={handleSelectChange} label={"Filter Rental"} data={rentals} isLoading={isLoadingRentals} id={"main-tenants-rental-select"}/>
                        </Grid>
                        <Grid item xs={4}>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant="contained" onClick={handleClickOpen} endIcon={<AddIcon/>}>
                                Add Tenant
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sx={{ marginTop: 3 }}>
                            {isLoadingRentalTenants ? (
                                <DummyTable />
                            ) : (
                                <TenantsTable rows={rentalTenants} isLoadingRentalTenants={isLoadingRentalTenants} handleClickPopOver={handleClickPopOver} id={popperId}/>
                            )}
                        </Grid>
                    </Grid>
                    <TenantsPopover popperId={popperId} openPopper={openPopper} anchorEl={anchorEl} handleClosePopOver={handleClosePopOver} handleClickOpenTenantDetails={handleClickOpenTenantDetails}/>

                    <FormDialog open={open} handleClose={handleClose} title={"Add A New Tenant"} handleSubmit={handleSubmitTenant} content={<TenantsForm handleChangeTenantNumber={handleChangeTenantNumber} rentals={rentals} tenant={tenantDetails} errorTenant={errorLoadingTenantDetails} loadedTenant={loadedTenant} unit={unit} units={units} isLoadedUnits={isLoadedUnits} handleChangeRental={handleSelectChange} handleChangeRentalUnit={handleChangeRentalUnit} isExistingTenant={isExistingTenant} handleChangeFirstName={handleChangeFirstName} handleChangeLastName={handleChangeLastName} loadingTenantsError={loadingTenantsError}/>}/>

                    <FormDialog open={openTenantDetails} handleClose={handleCloseTenantDetails} title={"Tenant Details"}/>

                    <TopSnack open={openSnack} handleCloseSnack={handleCloseSnack} message={message} severity={severity}/>
                </Grid>
            </Grid>
        </Box>
    )
}