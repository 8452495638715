import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import LoginPage from "scenes/loginPage";
import ProfilePage from "scenes/profilePage";
import Properties from "scenes/Properties/index";
import Tenants from "scenes/Tenants";
import Tickets from "scenes/Tickets";
import Payments from "scenes/Payments";
import Dashboard from "scenes/Dashboard";
import Units from "scenes/Properties/units";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "./theme";
import { Payment } from "@mui/icons-material";

function App() {
  const mode = useSelector((state) => state.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  // const [isSidebar, setIsSidebar] = useState(true);
  const isAuth = Boolean(useSelector((state) => state.token));
  // const isAuth = true;

  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route
                path="/home"
                element={isAuth ? <Dashboard /> : <Navigate to="/" />}
              />
              <Route
                path="/tenants"
                element={isAuth ? <Tenants /> : <Navigate to="/" />}
              />
              <Route
                path="/tickets"
                element={isAuth ? <Tickets /> : <Navigate to="/" />}
              />
              <Route
                path="/properties"
                element={isAuth ? <Properties /> : <Navigate to="/" />}
              />
              <Route
                path="/units"
                element={isAuth ? <Units /> : <Navigate to="/" />}
              />
              <Route
                path="/payments"
                element={isAuth ? <Payments /> : <Navigate to="/" />}
              />
              <Route
                path="/profile/:userId"
                element={isAuth ? <ProfilePage /> : <Navigate to="/" />}
              />
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
