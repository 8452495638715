import * as React from "react";
import axios from "axios";
import { baseURL } from "../API";
import { useSelector, useDispatch } from "react-redux";

// axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;


const useRentals = () => {
	const token = useSelector((state) => state.token)
	const landlordId = useSelector((state) => state.user.id)
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [rentals, setRentals] = React.useState([]);
	const [isLoadingRentals, setLoadingRentals] = React.useState(true);
	const [error, setError] = React.useState(false);
	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/landlords/rentals?landlord_id=${landlordId}`);
				setRentals(response.data.data);
				setLoadingRentals(false);
			} catch (e) {
				setError(true);
				setLoadingRentals(false);
			}
		};
		fetchData();
	}, [landlordId]);
	return { rentals, isLoadingRentals, error };
};

const useRentalUnits = () => {
	const token = useSelector((state) => state.token)
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const landlordId = useSelector((state) => state.user.id)
	const [rentalUnits, setRentalUnits] = React.useState("");
	const [isLoadingRentalUnits, setLoadingRentalUnits] = React.useState(true);
	const [error, setError] = React.useState(false);
	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/landlords/rentals/units?rental_id=${landlordId}`);
				setRentalUnits(response.data.data);
				setLoadingRentalUnits(false);
			} catch (e) {
				setError(true);
				setLoadingRentalUnits(false);
			}
		};
		fetchData();
	}, [landlordId]);
	return { rentalUnits, isLoadingRentalUnits, error };
};

const useHandleRentalFormSubmit = async (rentalName, rentalType, rentalLocation) => {
	const token = useSelector((state) => state.token)
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const landlordId = useSelector((state) => state.user.id)
    try {
        await axios.post(`${baseURL}/landlords/rentals`, {
            related_landlord: landlordId,
            rental_name: rentalName,
            rental_type:rentalType,
            location: rentalLocation,
        });
        // setSuccess(true);
        // setLoading(false);
    } catch (err) {
        console.log(err)
        // setLoading(false);
        // setError(true);
        // setErrcode(err.message);
    }
}

const useGetRentalTickets = () => {
	const token = useSelector((state) => state.token)
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const landlordId = useSelector((state) => state.user.id)
	const [rentalTickets, setRentalTickets] = React.useState([]);
	const [isLoadingRentalTickets, setLoadingRentalTickets] = React.useState(true);
	const [errorLoadingRentalTickets, setErrorLoadingRentalTickets] = React.useState(false);
	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/landlords/rentals/units/tickets?landlord_id=${landlordId}`);
				setRentalTickets(response.data.data);
				setLoadingRentalTickets(false);
			} catch (e) {
				setErrorLoadingRentalTickets(true);
				setLoadingRentalTickets(false);
			}
		};
		fetchData();
	}, [landlordId]);
	return { rentalTickets, isLoadingRentalTickets, errorLoadingRentalTickets };
};

const useGetRentalTenants = () => {
	const token = useSelector((state) => state.token)
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const landlordId = useSelector((state) => state.user.id)
	const [rentalTenants, setRentalTenants] = React.useState([]);
	const [isLoadingRentalTenants, setLoadingRentalTenants] = React.useState(true);
	const [errorLoadingRentalTenants, setErrorLoadingRentalTenants] = React.useState(false);
	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/landlords/rentals/tenants?landlord_id=${landlordId}`);
				setRentalTenants(response.data.data);
				setLoadingRentalTenants(false);
			} catch (e) {
				setErrorLoadingRentalTenants(true);
				setLoadingRentalTenants(false);
			}
		};
		fetchData();
	}, [landlordId]);
	return { rentalTenants, isLoadingRentalTenants, errorLoadingRentalTenants };
};

const useGetDashboardData = () => {
	const token = useSelector((state) => state.token)
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const landlordId = useSelector((state) => state.user.id)
	const [dashboardData, setDashbaordData] = React.useState({});
	const [isLoadingDashboardData, setLoadingDashboardData] = React.useState(true);
	const [errorLoadingDashboardData, setErrorLoadingDashboardData] = React.useState(false);
	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/landlords/dashboard?landlord_id=${landlordId}`);
				setDashbaordData(response.data.data);
				setLoadingDashboardData(false);
			} catch (e) {
				setErrorLoadingDashboardData(true);
				setLoadingDashboardData(false);
			}
		};
		fetchData();
	}, [landlordId]);
	return { dashboardData, isLoadingDashboardData, errorLoadingDashboardData };
};


const useGetPaymentsData = () => {
	const token = useSelector((state) => state.token)
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const landlordId = useSelector((state) => state.user.id)
	const [paymentsData, setPaymentsData] = React.useState([]);
	const [isLoadingPaymentsData, setLoadingPaymentsData] = React.useState(true);
	const [errorLoadingPaymentsData, setErrorLoadingPaymentsData] = React.useState(false);
	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/landlords/rentals/units/payments?landlord_id=${landlordId}`);
				setPaymentsData(response.data.data);
				setLoadingPaymentsData(false);
			} catch (e) {
				setErrorLoadingPaymentsData(true);
				setLoadingPaymentsData(false);
			}
		};
		fetchData();
	}, [landlordId]);
	return { paymentsData, isLoadingPaymentsData, errorLoadingPaymentsData };
};


export {
    useRentals, useHandleRentalFormSubmit, useRentalUnits, useGetRentalTickets, useGetRentalTenants, useGetDashboardData, useGetPaymentsData
}