import * as React from 'react';
import axios from "axios";
import { baseURL } from 'services/API';
import UnitsTable from '../../components/Tables/UnitsTable';
import DummyTable from 'components/Tables/DummyTable';
import { Grid, Box, CircularProgress } from "@mui/material";
import { useRentalUnits, useRentals } from 'services/hooks/rentals';
import Button from '@mui/material/Button';
import FormDialog from 'components/modal';
import BasicSelect from 'components/select';
import AddIcon from '@mui/icons-material/Add';
import UnitsForm from 'components/Forms/UnitsForm';
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";


const Units = () => {

    const {rentals, isLoadingRentals} = useRentals()
    const {rentalUnits, isLoadingRentalUnits, error} = useRentalUnits()
    const [open, setOpen] = React.useState(false);
    const [rentalId, setRentalId] = React.useState("");
    const [rentId, setRentId] = React.useState("");
    const [unitName, setUnitName] = React.useState("");
    const [isSidebar, setIsSidebar] = React.useState(true);
    // const [unitSize, setUnitSize] = React.useState("");
    const [rentCurrency, setRentCurrency] = React.useState("");
    const [rentalType, setRentalType] = React.useState("");
    const [unitRent, setUnitRent] = React.useState("");
    const [rentCycle, setRentCycle] = React.useState("");
    const [base64URL, setBase64URL] = React.useState("");
    const [file, setFile] = React.useState(null)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSelectChange = (event) => {
        setRentalId(event.target.value);
    };

    const handleChangeRental = (event) => {
        setRentId(event.target.value);
    };

    const handleChangeUnitName = (event) => {
        setUnitName(event.target.value)
    }

    const handleChangeRentalType = (event) => {
        setRentalType(event.target.value)
    }

    const handleChangeUnitRent = (event) => {
        setUnitRent(event.target.value)
    }

    const handleChangeRentCycle = (event) => {
        setRentCycle(event.target.value)
    }

    const handleChangeRentalCurrency = (event) => {
        setRentCurrency(event.target.value)
    }

    const getBase64 = file => {
        return new Promise(resolve => {
          let fileInfo;
          let baseURL = "";
          let reader = new FileReader();
    
          reader.readAsDataURL(file);
    
          reader.onload = () => {
            console.log("Called", reader);
            baseURL = reader.result;
            console.log(baseURL);
            resolve(baseURL);
          };
          console.log(fileInfo);
        });
    };

    const handleFileInputChange = e => {
        console.log(e.target.files[0]);
        let { file } = this.state;
    
        file = e.target.files[0];
    
        getBase64(file)
          .then(result => {
            file["base64"] = result;
            this.setState({
              base64URL: result,
              file
            });
          })
          .catch(err => {
            console.log(err);
          });
    
        this.setState({
          file: e.target.files[0]
        });
    };

    const handleRentalUnitFormSubmit = async () => {
        try {
            await axios.post(`${baseURL}/landlords/rentals/units`, {
                related_rental: rentId,
                unit_name: unitName,
                unit_type:rentalType,
                rent_currency: rentCurrency,
                unit_rent: unitRent,
                unit_rent_cycle: rentCycle,
                has_down_payment:"False",
                has_security_fee:"False",
                unit_cycle_down_payment:0,
                unit_rent_security_fee:0,
                files: []
            });
            // setSuccess(true);
            // setLoading(false);
            setOpen(false);
        } catch (err) {
            console.log(err)
            // setLoading(false);
            // setError(true);
            // setErrcode(err.message);
        }
    }

  return (

    <Box>
        <Navbar />
        <Grid container spacing={2}>
            <Grid item xs={2}>
                <Sidebar isSidebar={isSidebar}/>  
            </Grid>
            <Grid item xs={10} spacing={2}>
                <h1>Units for Property </h1>
                <Grid container>
                    <Grid container>
                            <Grid item xs={4}>
                                <BasicSelect value={rentalId} handleChange={handleSelectChange} label={"Select Property Name"} data={rentals} isLoading={isLoadingRentals}/>
                            </Grid>
                            <Grid item xs={4}>
                                
                            </Grid>
                            <Grid item xs={4}>
                                <Button variant="contained" onClick={handleClickOpen} endIcon={<AddIcon/>}>
                                    Add Property Unit
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sx={{ marginTop: 3 }}>
                                {isLoadingRentalUnits ? (
                                    <CircularProgress/>
                                ) : (
                                    <UnitsTable rows={rentalUnits} isLoadingRentalUnits={isLoadingRentalUnits}/>
                                )}
                            </Grid>
                        </Grid>
                            <FormDialog open={open} handleClose={handleClose} text={"Add all Unit Details"} content={<UnitsForm title={"Units Form"} rentals={rentals} handleChangeRental={handleChangeRental} handleChangeUnitName={handleChangeUnitName} handleChangeRentalCurrency={handleChangeRentalCurrency} handleChangeRentalType={handleChangeRentalType} handleChangeUnitRent={handleChangeUnitRent} handleChangeRentCycle={handleChangeRentCycle}/>} title={"Units Form"} handleSubmit={handleRentalUnitFormSubmit}/>
                    </Grid>
            </Grid>
        </Grid>
    </Box>
  );
};

export default Units;