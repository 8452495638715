import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Grid } from "@mui/material";

export default function UnitsForm(props) {
  return (
    <Grid container
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
    <Grid item xs={12} spacing={2}>
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select Property</InputLabel>
            <Select
            labelId="rental-id"
            id="rental-id"
            value={props.rental}
            label="Properties"
            onChange={props.handleChangeRental}
            >
              {props.rentals.map((value, index) => {
                return (
                  <MenuItem key={index} value={value.id}>{value.rental_name}</MenuItem>
                )
              })}
            </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} spacing={2}
        sx={{
          marginTop: "15px"
        }}
      >
        <TextField
            required
            id="outlined-required"
            label="Unit Name"
            onChange={props.handleChangeUnitName}
            fullWidth={true}
        />

        <TextField
            required
            id="outlined-required"
            label="Unit Rent"
            onChange={props.handleChangeUnitRent}
        />
      </Grid>
        
      <Box container 
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          marginTop: "15px"
        }}
      >
        <TextField
            label="Rent Currency"
            onChange={props.handleChangeRentalCurrency}
            select
            value={props.rentCurrency}
            name="Rent Currency"
            sx={{
              maxWidth: "150px"
            }}
            // error={Boolean(touched.gender) && Boolean(errors.gender)}
            // helperText={touched.gender && errors.gender}
        >
          <MenuItem value="UGX">UGX</MenuItem>
          <MenuItem  value="USD">USD</MenuItem>
        </TextField>

        <TextField
            label="Unit Type"
            onChange={props.handleChangeRentalType}
            select
            value={props.rentalType}
            name="Unit Type"
            sx={{
              maxWidth: "150px"
            }}
            // error={Boolean(touched.gender) && Boolean(errors.gender)}
            // helperText={touched.gender && errors.gender}
        >
          <MenuItem value="REGULAR">REGULAR</MenuItem>
          <MenuItem value="CONDOMINIUM">CONDOMINIUM</MenuItem>
          <MenuItem value="SEMI-DETACHED">SEMI DETACHED</MenuItem>
        </TextField>

        <TextField
            label="Rent Cycle"
            onChange={props.handleChangeRentCycle}
            select
            value={props.rentCycle}
            name="Rent Cycle"
            sx={{
              maxWidth: "150px"
            }}
            // error={Boolean(touched.gender) && Boolean(errors.gender)}
            // helperText={touched.gender && errors.gender}
        >
          <MenuItem value="DAILY">DAILY</MenuItem>
          <MenuItem value="WEEKLY">WEEKLY</MenuItem>
          <MenuItem value="MONTHLY">MONTHLY</MenuItem>
          <MenuItem value="QUARTERLY">QUARTERLY</MenuItem>
        </TextField>
      </Box>

      {/* <Box container>
        <hr/>
        <h5>Unit Amenities</h5>
      </Box> */}

      {/* <Grid container spacing={2}>
        <Grid item xs={6} spacing={2}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Has Down Payment</InputLabel>
                <Select
                labelId="rental-type"
                id="rental-type-id"
                value={props.rentIsDownPayment}
                label="Age"
                onChange={props.handleChangeIsRentDownPayment}
                >
                    <MenuItem value="True">YES</MenuItem>
                    <MenuItem value="False">NO</MenuItem>   
                </Select>
            </FormControl>
        </Grid>

        <Grid item xs={6} spacing={2}>
            <TextField
            required
            id="outlined-required"
            label="Down Payment Amount"
            onChange={props.handleChangeDownPayment}
            />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6} spacing={2}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Has Security Payment</InputLabel>
                <Select
                labelId="rental-type"
                id="rental-type-id"
                value={props.rentIsSecurityPayment}
                label="Age"
                onChange={props.handleChangeIsRentSecurityPayment}
                >
                    <MenuItem value="True">YES</MenuItem>
                    <MenuItem value="False">NO</MenuItem>   
                </Select>
            </FormControl>
        </Grid>

        <Grid item xs={6} spacing={2}>
            <TextField
            required
            id="outlined-required"
            label="Security Payment Amount"
            onChange={props.handleChangeSecurityPayment}
            />
        </Grid> */}
        {/* </Grid> */}
    </Grid>
  );
}