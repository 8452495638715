import * as React from 'react';
import { Grid, Box, Paper } from "@mui/material";
import PaymentsTable from 'components/Tables/PaymentsTable';
import TicketsTable from 'components/Tables/TicketsTable';
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import BasicCard from 'components/Card';
import DummyCard from 'components/DummyCard';
import DummyTable from 'components/Tables/DummyTable';
import DoughnutChart from 'components/Graphs/DoughnutChart';
import { useGetDashboardData } from 'services/hooks/rentals';
import Skeleton from '@mui/material/Skeleton';
import { formatDate } from 'utilities/date';

export default function Dashboard() {
    const [isSidebar, setIsSidebar] = React.useState(true);
    const [rental, setRental] = React.useState('')
    const [open, setOpen] = React.useState(false);
    const {dashboardData, isLoadingDashboardData, errorLoadingDashboardData} = useGetDashboardData()

    return (
        <Box>
            <Navbar />
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Sidebar isSidebar={isSidebar}/>  
                </Grid>
            {isLoadingDashboardData ? (
                <Grid item xs={10}>
                    <h1>Dashboard</h1>
                    <Grid container spacing={4}>
                        <Grid item xs={3}>
                            <DummyCard />
                        </Grid>
                        <Grid item xs={3}>
                            <DummyCard />
                        </Grid>
                        <Grid item xs={3}>
                            <DummyCard />
                        </Grid>
                        <Grid item xs={3}>
                            <DummyCard />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid container item xs={7} spacing={2}>
                            <Grid item xs={12} sx={{ marginTop: 1 }}>
                            <h2>Rent Due Tenants</h2>
                                <DummyTable/>
                            </Grid>
                        
                            <Grid item xs={12} sx={{ marginTop: 1 }}>
                            <h2>Tickets</h2>
                                <DummyTable/>
                            </Grid>
                        </Grid>
                        <Grid container item xs={5} spacing={2}
                            sx={{
                                marginTop: 4,
                                padding: 4,
                            }}
                        >   
                            <Paper sx={{
                                margin: 3,
                                padding: 4,
                                width: "100%"
                            }}>
                                <h2>Rent Payments</h2>
                                <Skeleton></Skeleton>
                            </Paper>                               
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                <Grid item xs={10}>
                    <h1>Dashboard</h1>
                    <Grid container spacing={4}>
                        <Grid item xs={3}>
                            <BasicCard name={"PROPERTIES"} value={dashboardData.properties}/>
                        </Grid>
                        <Grid item xs={3}>
                            <BasicCard name={"TICKETS"} value={dashboardData.tickets}/>
                        </Grid>
                        <Grid item xs={3}>
                            <BasicCard name={"RENT DUE"} value={"3M"}/>
                        </Grid>
                        <Grid item xs={3}>
                            <BasicCard name={"TENANTS"} value={dashboardData.tenants}/>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid container item xs={7} spacing={2}>
                            <Grid item xs={12} sx={{ marginTop: 1 }}>
                            <h2>Rent Due Tenants</h2>
                                <PaymentsTable rows={dashboardData.tenants_list}/>
                            </Grid>
                        
                            <Grid item xs={12} sx={{ marginTop: 1 }}>
                            <h2>Tickets</h2>
                                <TicketsTable rows={dashboardData.tickets_list} formatDate={formatDate}/>
                            </Grid>
                        </Grid>
                        <Grid container item xs={5} spacing={2}
                            sx={{
                                marginTop: 4,
                                padding: 4,
                            }}
                        >   
                            <Paper sx={{
                                margin: 3,
                                padding: 4,
                                width: "100%"
                            }}>
                                <h2>Rent Payments</h2>
                                <DoughnutChart/>
                            </Paper>                               
                        </Grid>
                    </Grid>
                </Grid>
             )}
            </Grid>
        </Box>
    )
}