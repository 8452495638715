import * as React from 'react';
import axios from "axios";
import { baseURL } from 'services/API';
import { Grid, Box } from "@mui/material";
import RentalsTable from "../../components/Tables/RentalsTable"
import DummyTable from 'components/Tables/DummyTable';
import Button from '@mui/material/Button';
import FormDialog from 'components/modal';
import BasicSelect from 'components/select';
import AddIcon from '@mui/icons-material/Add';
import { useRentals } from 'services/hooks/rentals';
import RentalsForm from 'components/Forms/RentalsForm';
import PropertyPopover from 'components/PopOvers/PropertyPopover';
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import ProgressScale from 'components/ProgressScale';
import { useNavigate } from "react-router-dom";


const Rentals = () => {

    const navigate = useNavigate()

    const {rentals, isLoadingRentals, error} = useRentals()
    const [rental, setRental] = React.useState('')
    const [open, setOpen] = React.useState(false);
    const [age, setAge] = React.useState('');
    const [isSidebar, setIsSidebar] = React.useState(true);

    const [rentalName, setRentalName] = React.useState('');
    const [rentalType, setRentalType] = React.useState('');
    const [rentalLocation, setRentalLocation] = React.useState('');

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openPopper = Boolean(anchorEl);
    const popperId = openPopper ? 'simple-popover' : undefined;

    const handleRentalNameChange = (event) => {
        setRentalName(event.target.value)
    }

    const handleChangeRentalType = (event) => {
        setRentalType(event.target.value)
    }

    const handleRentalLocationChange = (event) => {
        setRentalLocation(event.target.value)
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSelectChange = (event) => {
        setAge(event.target.value);
    };

    const handleClickPopOver = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClosePopOver = () => {
      setAnchorEl(null);
    };


    const handleRentalFormSubmit = async () => {
        try {
            await axios.post(`${baseURL}/landlords/rentals`, {
                related_landlord: 1,
                rental_name: rentalName,
                rental_type:rentalType,
                location: rentalLocation,
            });
            // setSuccess(true);
            // setLoading(false);
            setOpen(false);
        } catch (err) {
            console.log(err)
            // setLoading(false);
            // setError(true);
            // setErrcode(err.message);
        }
    }

    return (
        <Box>
            <Navbar />
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Sidebar isSidebar={isSidebar}/>  
                </Grid>
                <Grid item xs={10} spacing={2}>
                  {isLoadingRentals && (
                    <ProgressScale />
                  )}
                    <h1>Properties</h1>

                    <Grid container >
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                {/* <BasicSelect value={rental} handleChange={handleSelectChange} label={"Properties"} data={rentals}/> */}
                            </Grid>
                            <Grid item xs={4}>
                                
                            </Grid>
                            <Grid item xs={4}>
                                <Button variant="contained" onClick={handleClickOpen} endIcon={<AddIcon/>}>
                                    Add Properties
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sx={{ marginTop: 3 }}>
                                {isLoadingRentals ? (
                                  <DummyTable />
                                ) : (
                                  <RentalsTable rows={rentals} isLoadingRentals={isLoadingRentals} handleClickPopOver={handleClickPopOver} id={popperId}/>
                                )}
                            </Grid>
                        </Grid>
                            <PropertyPopover popperId={popperId} openPopper={openPopper} anchorEl={anchorEl} handleClosePopOver={handleClosePopOver} handleClickOpen={handleClickOpen} navigate={navigate}/>
                            <FormDialog open={open} handleClose={handleClose} text={"Add all Rentals Details"} content={<RentalsForm rentalType={rentalType} handleRentalNameChange={handleRentalNameChange} handleChangeRentalType={handleChangeRentalType} handleRentalLocationChange={handleRentalLocationChange}/>} title={"Rentals Form"} handleSubmit={handleRentalFormSubmit}/>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Rentals;