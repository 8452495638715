import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';


export default function DummyCard({}) {
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography variant="h5" component="div">
            <Skeleton></Skeleton>
        </Typography>
        <Typography variant="h1">
            <Skeleton></Skeleton>
        </Typography>
      </CardContent>
    </Card>
  );
}